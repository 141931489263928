import React, { useState } from "react";
import ButtonPrimary from "../Button/ButtonPrimary";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { addPayment } from "../../services/exchange";
import { paymentMethods } from "../../utils/paymentMethods";
import { WALLET_MINIMUM_AMOUNT } from "../../common/constants";
import { formatNumber } from "../../utils/convertToNumber";
import Loading from "../Loading";
import { toast } from "react-toastify";

const ExchangePayment = () => {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("wallet");

  const navigate = useNavigate();
  const location = useLocation();

  const { req, booking, selectedFlight, segment, company, exchangeSc } =
    location.state;

  const { ModifyPrice, ProductOptions } = selectedFlight;

  function checkWalletBalance() {
    if (
      enterprise?.method !== "postPaid" &&
      (!company ||
        company.wallet > WALLET_MINIMUM_AMOUNT + ModifyPrice?.TotalPrice)
    ) {
      // show error message
      return true;
    }
  }

  const handleClick = async () => {
    if (ModifyPrice?.CurrencyCode?.value === "ETB") {
      const data = {
        ...req,
        price: ModifyPrice?.TotalPrice + (exchangeSc || 0),
        tax: ModifyPrice?.TotalTaxes,
        payment: paymentMethod || "wallet",
        exchangeSC: exchangeSc || 0,
        id: booking?._id,
        companyId: company?._id,
        user,
      };

      const res = await addPayment(data, setLoading);

      if (res.ticket?.flight) {
        toast.success("Exchange Successful");
        navigate("/booking");
        return;
      }
    }
  };

  function getSegment(ref) {
    return segment?.find((segment) => segment?.id === ref);
  }

  function getTotalPrice() {
    const result = ModifyPrice?.TotalPrice + (exchangeSc || 0);
    return formatNumber(result);
  }

  if (loading) return <Loading />;

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-5 sm:p-6 xl:p-8 shadow container mx-auto">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="py-1 space-y-3">
            {ProductOptions?.map((product, index) => {
              return product?.Product[0]?.FlightSegment?.map(
                (segment, index) => {
                  const flight = getSegment(segment?.Flight?.FlightRef);

                  return (
                    <div key={index}>
                      <span className="text-lg text-neutral-500 font-semibold">
                        <h1> {flight?.carrier}</h1>
                      </span>
                      <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                        <h1>
                          {flight?.Departure?.location} -{" "}
                          {flight?.Arrival?.location}
                        </h1>
                      </span>
                      <span className="text-base font-medium mt-1 block">
                        {moment(flight?.Departure?.date).format(
                          "MMM DD, YYYY "
                        )}{" "}
                        -{" "}
                        {moment(flight?.Arrival?.date).format("MMM DD, YYYY ")}
                      </span>
                      <span className="text-base font-medium mt-1 block">
                        {flight?.Departure?.time.slice(0, -3)} -{" "}
                        {flight?.Arrival?.time.slice(0, -3)}
                      </span>
                    </div>
                  );
                }
              );
            })}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Fee detail</h3>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Total Taxes</span>
            <span>
              {ModifyPrice?.CurrencyCode?.value} {ModifyPrice?.TotalTaxes}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Total Fees</span>
            <span>
              {ModifyPrice?.CurrencyCode?.value} {ModifyPrice?.TotalFees}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service Charge</span>
            <span>
              {ModifyPrice?.CurrencyCode?.value} {exchangeSc}
            </span>
          </div>

          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>
              {ModifyPrice?.CurrencyCode?.value} {getTotalPrice()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-5 sm:p-6 xl:p-8 shadow container mx-auto">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="">
          {checkWalletBalance() && (
            <button
              onClick={() => {
                setPaymentMethod("wallet");
              }}
              className={`p-8 rounded-lg focus:outline-none mr-10  ${
                paymentMethod === "wallet"
                  ? "bg-neutral-800  text-white  h-36 pt-4"
                  : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
              }`}
            >
              <div className="flex flex-col items-center gap-2">
                <img
                  src={
                    "https://www.kindpng.com/picc/m/580-5809740_white-wallet-logo-png-wallet-icon-ios-transparent.png"
                  }
                  alt=""
                  className="w-16 my-2"
                />
                Wallet
              </div>
            </button>
          )}
          {company && (
            <button
              onClick={() => {
                setPaymentMethod("credit");
              }}
              className={`p-8 rounded-lg focus:outline-none mr-10  ${
                paymentMethod === "credit"
                  ? "bg-neutral-800  text-white  h-36 pt-4"
                  : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
              }`}
            >
              <div className="flex flex-col items-center gap-2">
                <img
                  src={
                    "https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs="
                  }
                  alt=""
                  className="w-16 my-2"
                />
                Credit
              </div>
            </button>
          )}
        </div>

        <h1 className="text-xl font-semibold">
          Selected Payment Method:{" "}
          <span className="text-main">
            {
              paymentMethods.find((method) => method.name === paymentMethod)
                ?.title
            }
          </span>
        </h1>

        {(paymentMethod === "wallet" || paymentMethod === "credit") && (
          <>
            <div className="lg:hidden row lg:p-0 space-y-2  ">
              <div className="col-12">
                <ButtonPrimary
                  className="bg-slate-50 
              border-1 border-red-500 text-black hover:text-white hover:bg-red-400
              focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 w-full rounded-3xl"
                >
                  Cancel Booking
                </ButtonPrimary>
              </div>
              <div className="col-12 ">
                <ButtonPrimary
                  onClick={handleClick}
                  className="w-full 
            border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
            focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl"
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>

            <div className="pt-8 lg:flex justify-between hidden gap-4">
              <div className="w-full">
                <ButtonPrimary
                  className="bg-slate-50 
              border-1 border-red-500 text-black hover:text-white hover:bg-red-400
              focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 w-full rounded-3xl"
                >
                  Cancel Booking
                </ButtonPrimary>
              </div>
              <div className="w-full">
                <ButtonPrimary
                  onClick={handleClick}
                  className="w-full 
            border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
            focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl
            "
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" lg:block flex-grow mb-10">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default ExchangePayment;
