import React, { Fragment, useState } from "react";
import { Tab } from "@headlessui/react";
import { toast } from "react-toastify";

import {
  USER_ROLE_EMPLOYEE,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import CorporateList from "../../../components/FlightBookingSteps/CorporateList";
import { currencyType } from "../../../utils/flightInfo";
import kacha from "../../../images/banks/kacha.png";

const PaymentMethodModal = ({
  paymentMethod,
  setPaymentMethod,
  company,
  totalPrice,
  enterprise,
  booking,
  user,
  selectedCorporate,
  setSelectedCorporate,
  currency,
}) => {
  const [showCorporateList, setShowCorporateList] = useState(false);

  const currentDate = new Date().toLocaleDateString();
  const departureDate = new Date(
    booking?.flight?.departureTime
  ).toLocaleDateString();

  if (totalPrice < 1) {
    toast.error("Something went wrong. Please Contact Sterling Team.");
    return null;
  }

  function creditPass() {
    if (
      company &&
      company?.creditType !== "" &&
      company?.creditLimit > totalPrice &&
      currentDate !== departureDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleCorporateSubmit = (selectedCorporate) => {
    setShowCorporateList(false);
    setSelectedCorporate(selectedCorporate);
  };

  function USDCreditPass() {
    if (
      company &&
      company?.USDCreditType !== "" &&
      company?.USDCreditLimit > totalPrice &&
      currentDate !== departureDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  const isSuper =
    user?.role === USER_ROLE_SUPERAGENT || user?.role === USER_ROLE_EMPLOYEE;

  return (
    <div className="px-4  bg-white ">
      <div className="mt-6 mx-2">
        <Tab.Group>
          {currencyType(currency) === "USD" ? (
            <Tab.List className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
              {USDCreditPass() ? (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        setPaymentMethod("USDCredit");
                        setSelectedCorporate(null);
                      }}
                      className={`p-8 rounded-lg focus:outline-none mr-10  ${
                        paymentMethod === "USDCredit"
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                          : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                      }`}
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src="https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs="
                          alt=""
                          className="w-20 my-2"
                        />
                        USD Credit
                      </div>
                    </button>
                  )}
                </Tab>
              ) : (
                <div className="w-full grid-cols-6 grid-rows-4">
                  <h1 className="text-red-600 leading-6">
                    * Insufficient USD Credit Limit. Please Contact Sterling
                    team for more information.
                  </h1>
                </div>
              )}
            </Tab.List>
          ) : (
            <Tab.List className="flex flex-wrap justify-start items-center gap-2 mx-2">
              {enterprise?.method !== "postPaid" &&
                (!company ||
                  company?.wallet > WALLET_MINIMUM_AMOUNT + totalPrice) && (
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        onClick={() => {
                          setPaymentMethod("wallet");
                        }}
                        className={`p-4 rounded-lg focus:outline-none mr-5 w-44  ${
                          paymentMethod === "wallet"
                            ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                            : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                        }`}
                      >
                        <div className="flex flex-col items-center gap-2">
                          <img
                            src="https://www.kindpng.com/picc/m/580-5809740_white-wallet-logo-png-wallet-icon-ios-transparent.png"
                            alt=""
                            className="w-16 my-2 hidden md:block"
                          />
                          Wallet
                        </div>
                      </button>
                    )}
                  </Tab>
                )}

              {(user?.role === USER_ROLE_SUPERAGENT ||
                user?.role === USER_ROLE_EMPLOYEE) && (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        setPaymentMethod("credit");
                        setShowCorporateList(!showCorporateList);
                      }}
                      className={`p-8 rounded-lg focus:outline-none mr-10  ${
                        paymentMethod === "credit"
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                          : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                      }`}
                    >
                      <img
                        src="https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs="
                        alt=""
                        className="w-20 my-2"
                        // className="w-16 my-2 hidden md:block"
                      />
                      Credit
                    </button>
                  )}
                </Tab>
              )}

              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("webirr_cbe");
                    }}
                    className={`p-4 rounded-lg focus:outline-none mr-5 w-44
             ${
               paymentMethod === "webirr_cbe"
                 ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                 : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
             }
            `}
                  >
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src="https://www.ethiopiatrustfund.org/wp-content/uploads/2018/09/cbe-logo.png"
                        alt=""
                        className="w-16 my-2"
                      />
                      CBE
                    </div>
                  </button>
                )}
              </Tab>

              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("webirr_awash");
                    }}
                    className={`p-4 rounded-lg focus:outline-none mr-5 w-44  ${
                      paymentMethod === "webirr_awash"
                        ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                        : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src="https://play-lh.googleusercontent.com/6W3_GN3BhqJffOg3XrjStJ7ICaQWdn9DD4lid4zPA0N7O0Jz0Ifq4ymCspxaO-QwvYw"
                        alt=""
                        className="w-16 my-2"
                      />
                      Awash Bank
                    </div>
                  </button>
                )}
              </Tab>
              {/* <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => {
                    setPaymentMethod("webirr_abyssinia");
                  }}
                  className={`p-4 rounded-lg focus:outline-none mr-5 w-44  ${
                    paymentMethod === "webirr_abyssinia"
                      ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                      : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                  }`}
                >
                  <div className="flex flex-col items-center gap-2">
                    <img
                      src="https://play-lh.googleusercontent.com/9UY26ys1vxxqYPX_zL4BCBjuaCBMoXIf9yQ7RhPEqAoQJVnczlgFpWUCRem1GXzt3A"
                      alt=""
                      className="w-16 my-2"
                    />
                    Abyssinia Bank
                  </div>
                </button>
              )}
            </Tab> */}
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("amhara");
                    }}
                    className={`p-4 rounded-lg focus:outline-none mr-5 w-44 ${
                      paymentMethod === "amhara"
                        ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                        : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center gap-2">
                      <img
                        src="https://pbs.twimg.com/profile_images/1542232022726447104/dQxN5Fap_400x400.jpg"
                        alt=""
                        className="w-16 my-2"
                      />
                      Amhara Bank
                    </div>
                  </button>
                )}
              </Tab>

              {creditPass() && !isSuper && (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        setPaymentMethod("credit");
                      }}
                      // disabled={checkDueDate()}
                      className={`p-4 rounded-lg focus:outline-none mr-5 w-44  ${
                        paymentMethod === "credit"
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900 h-36 pt-4"
                          : "text-neutral-6000 dark:text-neutral-400 border border-gray-800 shadow-xl h-36 pt-4"
                      }`}
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src="https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs="
                          alt=""
                          className="w-20 my-2"
                        />
                        Credit
                      </div>
                    </button>
                  )}
                </Tab>
              )}
              {company?.kachaCredit && (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        setPaymentMethod("kacha");
                        setSelectedCorporate(null);
                      }}
                      // disabled={checkDueDate()}
                      className={`p-6 rounded-lg focus:outline-none mr-10  ${
                        paymentMethod === "kacha"
                          ? "bg-neutral-800 text-white h-36 pt-4"
                          : "text-neutral-6000 border border-gray-800 shadow-xl h-36 pt-4"
                      }`}
                    >
                      <div className="flex flex-col items-center ">
                        <img src={kacha} alt="" className="w-20 mb-1" />
                        <p className="">Kacha Credit</p>
                      </div>
                    </button>
                  )}
                </Tab>
              )}
            </Tab.List>
          )}
        </Tab.Group>

        <div className="text-yellow-500 px-4 mt-5">
          {company &&
            paymentMethod === "wallet" &&
            company?.wallet < WALLET_MINIMUM_AMOUNT + totalPrice && (
              <h1 className="text-red-500 text-sm border border-red-500 rounded-md py-2 my-2">
                * You have reached your Wallet Balance please deposit to
                continue first.
              </h1>
            )}

          {!creditPass() && !isSuper && (
            <div className="text-red-500 text-sm border border-red-500 rounded-md py-2 my-2">
              {currentDate === departureDate ? (
                <span>
                  * You can't use Credit Limit for today's flight. Please select
                  another payment method.
                </span>
              ) : (
                <span>
                  Today's sales are about to exceed your credit limit. Please
                  pay the outstanding balance and continue purchasing tickets
                  using your daily credit allowance
                </span>
              )}
            </div>
          )}
        </div>

        <div className="p-4">
          <h1 className="text-xl font-medium">
            Total Price: {currency} {convertNumbThousand(totalPrice)}
          </h1>
          <h1 className="text-xl font-medium capitalize">
            Selected Payment: {paymentMethod}
          </h1>
          {selectedCorporate && (
            <h1 className="text-lg font-medium">
              Selected Corporate Name: {selectedCorporate}
            </h1>
          )}
        </div>
      </div>
      {paymentMethod === "credit" && !company && (
        <CorporateList
          show={showCorporateList}
          handleClose={handleCorporateSubmit}
          setShowCorporateList={setShowCorporateList}
        />
      )}
    </div>
  );
};

export default PaymentMethodModal;
