import React, { useEffect, useState } from "react";
import moment from "moment";

import Loading from "../Loading";
import {
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
} from "../../common/constants";
import { flightSearchList } from "../../utils/flightSearchList";
import FlightDatesRangeInput from "../../Pages/Home/Components/FlightDatesRangeInput";
import { useDispatch, useSelector } from "react-redux";
import {
  exchangeSearch,
  initiateExchange,
  reset,
} from "../../redux2/exchange/exchangeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectPassenger from "./components/SelectPassenger";
import SelectSegment from "./components/SelectSegment";

const ExchangeSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const Locator = query.get("Locator");

  const { booking } = location.state;

  const { data, offer, isLoading } = useSelector((state) => state.exchange);

  useEffect(() => {
    dispatch(initiateExchange(Locator));
  }, [dispatch, Locator]);

  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedPassenger, setSelectedPassenger] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [searchData, setSearchData] = useState(null);

  const origin = booking?.flight?.origin;
  const destination = booking?.flight?.destination;

  console.log({ selectedFlight });

  function getFlightName(code) {
    if (!code) return;
    return UseFlightName(code);
  }

  function UseFlightName(code) {
    return flightSearchList(code)?.filter((flight) => {
      return flight?.code === code;
    })?.[0]?.name;
  }

  function getTicketByRef(ref) {
    return data?.Reservation?.Receipt?.find(
      (receipt) => receipt?.Document?.[0]?.TravelerIdentifierRef?.id === ref
    ).Document[0]?.Number;
  }

  useEffect(() => {
    setSelectedPassenger([]);
    setSelectedFlight([]);
    if (offer?.CatalogOfferingsAirChangeResponse?.Result?.Error) {
      toast.error(
        offer?.CatalogOfferingsAirChangeResponse?.Result?.Error?.[0]?.Message
      );
    } else if (offer) {
      navigate(`/exchange-offer?workbench=${data?.Identifier?.value}`, {
        state: { offer, booking, data, searchData },
      });
      dispatch(reset());
    }
  }, [offer, navigate, data, dispatch, booking, searchData]);

  const handleClick = async () => {
    if (selectedFlight.length === 0) {
      toast.error("Please select at least one flight.");
      return;
    }

    if (selectedPassenger.length === 0) {
      toast.error("Please select at least one passenger.");
      return;
    }

    const request = {
      // SearchCriteriaFlight: [
      //   {
      //     departureDate: moment(dateRangeValue.startDate).format("YYYY-MM-DD"),
      //     // departureTime: moment(dateRangeValue.startDate).format("HH:mm:ss"),
      //     From: origin,
      //     To: destination,
      //   },
      // ],
      SearchCriteriaFlight: OD.map((flight, index) => ({
        departureDate:
          index === 0
            ? moment(dateRangeValue.startDate).format("YYYY-MM-DD")
            : moment(dateRangeValue.endDate).format("YYYY-MM-DD"),
        departureTime: moment(dateRangeValue.startDate).format("HH:mm:ss"),
        From: flight?.From,
        To: flight?.To,
      })),
      PassengerCriteria: selectedPassenger.map((passenger) => ({
        number: 1,
        passengerTypeCode: passenger.passengerTypeCode,
        TravelerIdentifierRef: {
          id: passenger.id,
        },
      })),
      BuildFromReservationWorkbench: {
        ReservationIdentifier: {
          Identifier: {
            authority: "Travelport",
            value: data?.Identifier?.value,
          },
        },
        OfferIdentifier: {
          id: data?.Reservation?.Offer?.[0]?.id,
        },
        // ProductIdentifier: [
        //   {
        //     id: data?.Reservation?.Offer?.[0]?.Product?.[0]?.id,
        //   },
        // ],
        ProductIdentifier: selectedFlight.map((flight) => ({
          id: flight.id,
        })),
      },
    };
    // console.log(request.SearchCriteriaFlight);
    setSearchData(request);
    dispatch(exchangeSearch(request));
  };

  const Segments = data?.Reservation?.Offer[0]?.Product;
  let OD =
    selectedFlight?.length > 0 &&
    selectedFlight?.map((flight) => {
      return {
        From: flight?.FlightSegment[0]?.Flight?.Departure?.location,
        To: flight?.FlightSegment.at(-1)?.Flight?.Arrival?.location,
        Id: flight?.FlightSegment[0].sequence,
      };
    });

  OD = OD.length > 0 && OD?.sort((a, b) => a.Id - b.Id);

  function getSegment(ref) {
    return data?.Reservation?.Offer[0]?.Product?.find(
      (segment) => segment?.id === ref
    );
  }

  if (isLoading) return <Loading />;

  return (
    <div>
      <div className="w-full flex flex-col justify-center items-center mx-2 ">
        <h1 className="text-xl font-semibold my-6 text-center text-gray-800 w-1/2">
          Exchange Search
        </h1>

        <SelectSegment
          segments={Segments}
          selectedFlight={selectedFlight}
          setSelectedFlight={setSelectedFlight}
          getSegment={getSegment}
        />

        <div className="max-w-5xl w-full mx-auto mb-10">
          <form className="opacity-[0.96] w-full mt-6 rounded-xl xl:rounded-xl border-1 border-black shadow-2xl bg-white z-10">
            <div className="flex flex-1 rounded-full">
              <div
                className={`flex flex-1 relative [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`}
              >
                <div className="flex-1">
                  <input
                    className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-bold text-black placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                    value={getFlightName(OD?.[0]?.From || origin)}
                  />
                  <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                    <span className="line-clamp-1">
                      Flying from{" "}
                      <span className="font-bold">{OD?.[0]?.From}</span>
                    </span>
                  </span>
                </div>
              </div>
              <div
                className={`flex flex-1 relative [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`}
              >
                <div className="flex-1">
                  <input
                    className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-bold text-black placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                    value={getFlightName(OD?.[0]?.To || destination)}
                  />
                  <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                    <span className="line-clamp-1">
                      Flying to <span className="font-bold">{OD?.[0]?.To}</span>
                    </span>
                  </span>
                </div>
              </div>

              <FlightDatesRangeInput
                exchange={true}
                defaultDateValue={dateRangeValue}
                tripType={
                  selectedFlight?.length > 1
                    ? TRIP_TYPE_ROUND_TRIP
                    : TRIP_TYPE_ONE_WAY
                }
                onChange={(data) => {
                  setDateRangeValue(data.stateDate);
                }}
                className="flex-1"
              />
              {/* <div className="pr-4 z-20 mt-3">
                <button
                  className="h-14 w-14 rounded-full bg-main hover:bg-main-700 flex items-center justify-center text-neutral-50 focus:outline-none"
                  onClick={handleSearch}
                >
                  <i className="fas fa-search text-lg"></i>
                </button>
              </div> */}
            </div>
          </form>
        </div>
        <SelectPassenger
          passengers={data?.Reservation?.Traveler}
          selectedPassenger={selectedPassenger}
          setSelectedPassenger={setSelectedPassenger}
          getTicketByRef={getTicketByRef}
        />
      </div>
      {dateRangeValue.startDate && (
        <div className="w-full p-4 flex justify-center mt-10 container">
          <button
            className="bg-main text-white px-6 py-2 rounded-lg ml-2"
            onClick={handleClick}
          >
            Next
          </button>
        </div>
      )}
      {/* <div
        className={`nc-SectionGridFilterCard mt-8 px-4 lg:px-0 lg:mt-16 pb-24 lg:pb-20 lg:mx-28`}
        data-nc-id="SectionGridFilterCard"
      >
        <h1 className="text-4xl font-semibold mx-12 text-gray-800 ">
          Flight Result
        </h1>
        <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
          {airPrice?.length > 0 &&
            airPrice?.map((item, index) => (
              <SearchFlight
                key={item?._attributes?.Key}
                flight={item}
                flights={flights}
                flightDetail={flightDetail}
                bookingRef={bookingRef}
                setSelectedFlight={setSelectedFlight}
                onSelect={onSelect}
              />
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default ExchangeSearch;
